<section id="error">
    <div class="container-fluid forgot-password-bg">
        <div class="row full-height-vh">
            <div class="col-12 d-flex align-items-center justify-content-center">
                <div class="row">
                    <div class="col-sm-12 text-center">
                        <img  class="img-fluid error-img mt-2" src="../../../assets/img/error.png" alt="" height="500" width="500">
                        <div class="text-white mt-4 porteLetra mt-4"><b>Error 404</b></div>
                        <div class="text-white mt-5 porteLetra mb-4"><b>Página No Encontrada</b></div>

                        <button class="btn btn-primary btn-lg mt-5 mb-4"><a [routerLink]="['/']" class="text-decoration-none text-white"><b>Volver</b></a></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
