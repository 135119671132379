<section>
    
    <div class="modal-content modal-fondo">
        <div class="modal-header">
            <h4 class="modal-title titulo">Reporte de precios</h4>
        </div>
    </div>
    <div class="modal-body">
        <div style="max-width: 99%;" class="container">
            <!-- La propiedad footerHeight oculta el pie de la tabla [footerHeight]="50" -->

            <input type="file" id="customFile" (change)="selectFile($event)">    &nbsp;&nbsp;&nbsp;
            <button class="btn btn-primary"  (click)="upload()">Guardar precios</button>

            &nbsp;&nbsp;&nbsp;&nbsp;
            <button class="btn btn-primary"  (click)="Mostarproductos()">Mostar productos cargados</button>
        <br/>

        <div class="card px-2">
            <div class="row pb">
                <div class="row-tabla">
                    <div class="row p-0">

                        <div class="col-md-12 p-0" *ngIf="flagCargando">
                            <ngb-progressbar [value]="100" [striped]="true" [animated]="true"></ngb-progressbar>
                        </div>
                        <!-- La propiedad footerHeight oculta el pie de la tabla [footerHeight]="50" -->
                        <ngx-datatable #tabCatalagoProductos
                            style="font-size: 14.5px !important;padding-left: 30px; text-align: left;" class="material" #tabCatalogo
                            [rows]="arrayProductos | paginate: configCatalogo" [columnMode]="'force'"
                            [rowHeight]="'auto'" [scrollbarH]="false" [reorderable]="true"
                            [messages]="{emptyMessage:'No hay datos en la tabla.'}">

                            <!--<ngx-datatable-column name="Nro" prop="numero" [width]="10">
                                <ng-template ngx-datatable-cell-template let-value="value">
                                    {{value | uppercase}}
                                </ng-template>
                            </ngx-datatable-column>-->

                            <ngx-datatable-column name="Especie" prop="nombreEspecie" [width]="60">
                                <ng-template ngx-datatable-cell-template let-value="value">
                                    {{value | uppercase}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Tipo" prop="nombreTipo" [width]="150">
                                <ng-template ngx-datatable-cell-template let-value="value">
                                    {{value | uppercase}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Variedad" prop="nombreVariedad" [width]="140">
                                <ng-template ngx-datatable-cell-template let-value="value">
                                    {{value | uppercase}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Envase" prop="tipoEnvase" [width]="30">
                                <ng-template ngx-datatable-cell-template let-value="value">
                                    {{value | uppercase}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Cantidad" prop="unidadCantidad" [width]="60">
                                <ng-template ngx-datatable-cell-template let-value="value">
                                    {{value}}
                                </ng-template>
                            </ngx-datatable-column>
                            
                            <ngx-datatable-column name="Precio" prop="precioporUnidad" [width]="70">
                                <ng-template ngx-datatable-cell-template let-value="value">
                                    {{value | currency:'CLP':''}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Eliminar" prop="Eliminar" [width]="60">
                                <ng-template ngx-datatable-cell-template let-row="row" let-value="value"
                                    let-rowIndex="rowIndex">
                                    <a><button class="btn btn-trash" placement="left" ngbTooltip="Eliminar producto"
                                            (click)="deletePrice(rowIndex);"><i
                                                class="fa fa-trash fa-2x"></i></button></a>
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </div>
                <br>
                <!-- Implementar Paginación: paginador y seleccionador!-->
                <div style="justify-content: center !important;"
                    class="d-flex justify-content-between mt-2 mb-2 py-3">
                    <div style="display: flex;">
                        <pagination-controls class="custom-pagination" style="color: black !important;"
                            [id]="'paginationCatalogo'" [autoHide]="false" [responsive]="true"
                            previousLabel="Anterior" nextLabel="Siguiente"
                            (pageChange)="onPageChangeCatalogo($event)">
                        </pagination-controls>&nbsp;
                        <div style="margin-left: 15px !important;">
                            <select class="selector custom-select" style="width: auto; color: black !important;"
                                name="pageSize" [(ngModel)]="configCatalogo.itemsPerPage">
                                <option class="selector" [ngValue]="10">10</option>
                                <option class="selector" [ngValue]="25">25</option>
                                <option class="selector" [ngValue]="50">50</option>
                                <option class="selector" [ngValue]="100">100</option>
                                <option class="selector" [ngValue]="1000">1000</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        </div>
    </div>
    <div class="modal-footer">
        <button type="button" style="color: white !important;" class="btn btn-raised btn-danger btn-cerrar"
        (click)="cerrarModal();"><b>Cerrar</b></button>
    </div>
</section>