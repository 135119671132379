import { Component, OnInit, Output, EventEmitter, OnDestroy, Input, ViewChild, enableProdMode } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import swal from 'sweetalert2';
import { DetallePedidoStoreService } from 'src/app/services/local-session/detalle-pedido-store.service';
import { LoginStoreService } from 'src/app/services/local-session/login-store.services';
import { BayerService } from 'src/app/services/bayer.service';
import { EmailService } from 'src/app/services/email.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { Email } from 'src/app/model/email';
import { EmailEnum } from 'src/app/enum/email-enum';
import { EmailReceptorEnum } from 'src/app/enum/email-receptor-enum';
import { LoginService } from 'src/app/services/login.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.scss']
})
export class usuariosComponent implements OnInit, OnDestroy {
  @Output() envioCerrarModal = new EventEmitter();
  @Output() actualizoGrilla = new EventEmitter();
  @Output() cancelaProductCompra = new EventEmitter();
  @Output() eliminarprecio = new EventEmitter();
  @Input() nuevoProductoCarro: any;
  @Input() mostarcarro: any;  

  public arrayProductos = new Array();

  public configCatalogo = {
    id: 'paginationCatalogo',
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: this.arrayProductos.length
  };

  private modalReference: NgbModalRef;
  private carroCompraSubscription: Subscription;
  private emialSubscription: Subscription;

  public fechaHoy = new Date();
  public flagMostrarTabla: boolean = true;
  public flagCargando: boolean = false;
  public activaCierreModal: boolean = false;
  fileName = '';
  listaProductosCarro = [];
  listaEnvioProductos = [];
  envListCarroVacio = [];
  nuevoProductoAgregado: any;
  totalPedido: string;
  capturaIdCompra: number;
  fechaActual: string;
  comentario: string;
  email: string;
  mantenerCarro = false;
  activaDescuento: boolean;
  formatter = new Intl.NumberFormat('es-CL', {
    style: 'currency',
    currency: 'CLP',
    minimumFractionDigits: 0
  })
  datosusuarios: any;
  selectedFiles: FileList;
  currentFileUpload: File;

  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private loginStoreService: LoginStoreService,
    private loginService: LoginService,
    private detallePedidoStoreService: DetallePedidoStoreService,
    private bayerService: BayerService,
    private emailService: EmailService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    console.log("inicio usuario")
    setTimeout(() =>
      this.cargarComponente(), 200);
  }

  cargarComponente() {

    this.flagCargando = true;
    this.loginService.detalleTodosUsuarios().subscribe(users =>{          
      this.listaProductosCarro =  users; 
      for (let x = 0; x < users.length; x++) {
        if (users[x].enabled == true){
          users[x].estado = "Habilitado";
        } else {
          users[x].estado = "Deshabilitado";
        }
        console.log("Habilitado",users[x].estado);
      }        
      console.log("detalleTodosUsuarios",users, "ffff", this.listaProductosCarro);
    });
  }

  // Emite un output de cierre de modal al padre
  cerrarModal() {
    console.log("cerrarModal");
    this.activaCierreModal = true;
    this.envioCerrarModal.emit();
    this.mantenerCarro = false;    
    console.log("cerrarModalfin");
  }

  upload() {
    this.currentFileUpload = this.selectedFiles.item(0);
    console.log("upload",this.currentFileUpload);
    var hola = this.loginService.uploadfileUsers(this.currentFileUpload);
    console.log("Fin upload", hola);
    this.cargarComponente();
  }

  selectFile(event) {
    this.selectedFiles = event.target.files;
  }

  onFileSelected(event) {
    const dddd = event.target.files;
    this.currentFileUpload = this.selectedFiles.item(0); 
    console.log(this.currentFileUpload);
  }

  ngOnDestroy(): void {

  }

  onPageChangeCatalogo(event) {
    if (event) {
      this.configCatalogo.currentPage = event;
    }
  }

  eliminarUsuario(event){
    if (event != null || event != undefined) {
      console.log(event)
      for (let i = 0; i < this.listaProductosCarro.length; i++) {
        if (i === event){
          console.log("eliminarUsuario",this.listaProductosCarro[i]);
          this.bayerService.deleteUser(this.listaProductosCarro[i].id).subscribe();
          this.cerrarModal();
        }
      }
    } else {
      console.error('No recibe evento!',event);
    }
  }
}
