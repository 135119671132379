<section>
    <div class="col-12 text-right">
        <div class="cont-unificado">
            <div class="col-md-12 p-0" *ngIf="flagCargando">
                <ngb-progressbar [value]="100" [striped]="true" [animated]="true"></ngb-progressbar>
            </div>
        </div>
    </div>

    <header>
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light border-3 border-primary">
                <div class="container-fluid mb-1">
                    <a>
                        <img src="../../../assets/icons/ico/bayer-chiquito.png">
                    </a>
                </div>

                <div id="MenuNavegacion" class="collapse navbar-collapse">
                    <ul class="navbar-nav ms-3">
                        <li class="nav-item">
                            <a class="nav-link text-nowrap mt-2" style="color: black;"><b>Fecha {{fechaActual}}</b></a>
                        </li>
                    </ul>
                    <ul class="navbar-nav ms-3">
                        <li class="nav-item">
                            <a style="color: black;" class="nav-link text-nowrap"><i class="fa fa-user fa-2x"></i><b
                                    class="mb-1 ml-1"> User {{nombreUser}}</b></a>
                        </li>
                    </ul>
                    <ul *ngIf="this.administrador" class="navbar-nav ms-3">
                        <li class="nav-item">
                            <a style="color: black;" class="nav-link text-nowrap" (click)="opencarroCompra(usuario);"><i
                                    class="fa fa-user fa-2x"></i><b class="mb-2 ml-1">Administrar Usuarios</b></a>
                        </li>
                    </ul>
                    <ul *ngIf="this.administrador" class="navbar-nav ms-3">
                        <li class="nav-item">
                            <a style="color: black;" class="nav-link text-nowrap" (click)="opencarroCompra(precios);"><i
                                    class="fa fa-user fa-2x"></i><b class="mb-2 ml-1">Administrar precios</b></a>
                        </li>
                    </ul>
                    
                    <ul class="navbar-nav ms-3">
                        <li class="nav-item">
                            <a style="color: black;" class="nav-link text-nowrap" (click)="opencarroCompra(ordenDeCompra);"><i
                                    class="fa fa-shopping-cart  fa-2x"></i><b class="mb-2 ml-1"></b></a>
                        </li>
                    </ul>
                    <ul class="navbar-nav ms-3">
                        <li class="nav-item">
                            <a style="color: black;" class="nav-link text-nowrap" (click)="logout();"><i
                                    class="fa fa-sign-out fa-2x"></i><b class="mb-2 ml-1">Cerrar sesión</b></a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </header>
    <!-- <br> -->
    <div class="banner-1 mb">
        <img class="imagen-1" src="../../../assets/icons/fondo-catalogo-grande.jpg">
        <label class="texto-encima ml-5"><i>Catálogo en línea</i></label>
        <div class="banner-2">

        </div>
        <!-- VERIFICAR ESTA LÍNEA CELESTE EL BANNER CATÁLOGO -->
        <!--<div class="inner-line lmnt-theme-primary-bg"></div>-->
        <div class="banner-3">

        </div>
    </div>



    <div class="row">
        <div class="col-12-lg">

            <div class="container">
                <div class="card cardCatalogo p-3">
                    <h4 class="text-left" style="color: black;">Buscador en catálogo</h4>
                    <div class="row pb-1 ml-4">
                        <div class="col-lg-3 col-md-3 col-sm-12 text-left">
                            <label for="especie" class="texto-color">Especie *</label>
                            <ng-select style="color: #857c85 !important;" placeholder="--Seleccione una opción--"
                                class="form-control form-control-sm" notFoundText="Sin datos!" [clearable]="false"
                                [(ngModel)]="especie" (change)="onChangeEspecies($event);">
                                <!-- <ng-option [value]="0"> </ng-option> -->
                                <ng-option *ngFor="let e of arrayEspecies" [value]="e.id">{{e.nombreEspecie |
                                    uppercase}}
                                </ng-option>
                            </ng-select>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-12 text-left">
                            <label for="tipo" class="texto-color">Tipo *</label>
                            <ng-select
                                *ngIf="flagActivoTipo === false || this.flagDesactivoTipo === true || especie === 0 && tipo === null"
                                style="color: #857c85 !important;" placeholder="--Seleccione una opción--"
                                class="form-control form-control-sm" notFoundText="Sin datos!" [clearable]="false"
                                [(ngModel)]="tipo" [disabled]="true">
                                <ng-option>
                                </ng-option>
                            </ng-select>
                            <ng-select *ngIf="flagActivoTipo === true && especie !== 0 || especie > 0"
                                style="color: #857c85 !important;" placeholder="--Seleccione una opción--"
                                class="form-control form-control-sm" notFoundText="Sin datos!" [clearable]="false"
                                [(ngModel)]="tipo" (change)="onChangeTipos($event);">
                                <ng-option *ngFor="let t of arrayTipos" [value]="t.id">{{t.nombreTipo | uppercase}}
                                </ng-option>
                            </ng-select>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-12 text-left">
                            <label for="variedad" class="texto-color">Variedad *</label>
                            <ng-select *ngIf="flagActivoVariedad === false && tipo !== 0"
                                style="color: #857c85 !important;" placeholder="--Seleccione una opción--"
                                class="form-control form-control-sm" notFoundText="Sin datos!" [clearable]="false"
                                [(ngModel)]="tipo" [disabled]="true">
                                <ng-option>
                                </ng-option>
                            </ng-select>
                            <ng-select *ngIf="flagActivoVariedad === true || tipo === 0 || tipo > 0"
                                style="color: #857c85 !important;" placeholder="--Seleccione una opción--"
                                class="form-control form-control-sm" notFoundText="Sin datos!" [clearable]="false"
                                [(ngModel)]="variedad" (change)="onChangeVariedades($event);">
                                <ng-option *ngFor="let v of arrayVariedades" [value]="v.id">{{v.nombreVariedad}}
                                </ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <br>
                    <div class="row pb-2 ml-5 col-lg-12">
                        <div class="col-lg-9 col-md-9 col-sm-9 text-left"></div>
                        <div class="col-lg-2 col-md-2 col-sm-2 text-left">
                            <div class="input-group-append">
                                <button *ngIf="especie > 0" class="btn btn-buscar mr-4 mt-2 pr-5 pl-5" type="button"
                                    (click)="buscarProductos();" triggers="mouseenter:mouseleave"><b>Buscar</b></button>

                                <!-- Se quita el disable: [disabled]="especie === 0 || especie == null"-->
                                <button *ngIf="especie <= 0 || especie == null" placement="top"
                                    ngbTooltip="¡Debe seleccionar un tipo de especie para iniciar la búsqueda!"
                                    class="btn btn-buscar mr-4 mt-2 pr-5 pl-5" type="button"><b>Buscar</b></button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br>

            <div class="card px-2">
                <div class="row pb">
                    <div class="row-tabla">
                        <div class="row p-0">

                            <div class="col-md-12 p-0" *ngIf="flagCargando">
                                <ngb-progressbar [value]="100" [striped]="true" [animated]="true"></ngb-progressbar>
                            </div>
                            <!-- La propiedad footerHeight oculta el pie de la tabla [footerHeight]="50" -->
                            <ngx-datatable #tabCatalagoProductos
                                style="font-size: 14.5px !important;padding-left: 30px; text-align: left;" class="material" #tabCatalogo
                                [rows]="arrayProductos | paginate: configCatalogo" [columnMode]="'force'"
                                [rowHeight]="'auto'" [scrollbarH]="false" [reorderable]="true"
                                [messages]="{emptyMessage:'No hay datos en la tabla.'}">

                                <ngx-datatable-column name="Especie" prop="nombreEspecie" [width]="60">
                                    <ng-template ngx-datatable-cell-template let-value="value">
                                        {{value | uppercase}}
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Tipo" prop="nombreTipo" [width]="150">
                                    <ng-template ngx-datatable-cell-template let-value="value">
                                        {{value | uppercase}}
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Variedad" prop="nombreVariedad" [width]="140">
                                    <ng-template ngx-datatable-cell-template let-value="value">
                                        {{value | uppercase}}
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Envase" prop="tipoEnvase" [width]="50">
                                    <ng-template ngx-datatable-cell-template let-value="value">
                                        {{value | uppercase}}
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Cantidad" prop="unidadCantidad" [width]="60">
                                    <ng-template ngx-datatable-cell-template let-value="value">
                                        {{value}}
                                    </ng-template>
                                </ngx-datatable-column>
                                
                                <ngx-datatable-column name="Precio Unidad" prop="valorXunidad"  [width]="70">
                                    <ng-template ngx-datatable-cell-template let-value="value">
                                        {{value | currency:'CLP':''}}
                                    </ng-template>
                                </ngx-datatable-column>

                                <ngx-datatable-column name="Descuento" prop="descuento" [width]="60">
                                    <ng-template ngx-datatable-cell-template let-value="value">
                                        {{value}}  %
                                    </ng-template>
                                </ngx-datatable-column>

                                <ngx-datatable-column name="Precio" prop="precioporUnidad" [width]="70">
                                    <ng-template ngx-datatable-cell-template let-value="value">
                                        {{value | currency:'CLP':''}}
                                    </ng-template>
                                </ngx-datatable-column>

                                <ngx-datatable-column name="Cantidad" prop="cantidad" [width]="140">
                                    <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                                        <div class="row ml-2">
                                            <div class="col-sm-2">
                                                <button type="button" triggers="mouseenter:mouseleave"
                                                    class="btn btn-danger btn-circle-red"
                                                    (click)="disminuirCantidad(rowIndex)"><b>-</b></button>
                                            </div>
                                            <div class="col-sm-4 ml-4">
                                                <input disabled min="0" (blur)="updateValue($event, rowIndex)"
                                                    (keyup.enter)="updateValue($event, rowIndex)"
                                                    class="form-control text-center" [(ngModel)]="row.cantidad" />
                                            </div>
                                            <div class="col-sm-2">
                                                <button type="button" triggers="mouseenter:mouseleave"
                                                    class="btn btn-success btn-circle"
                                                    (click)="aumentarCantidad(rowIndex)"><b>+</b></button>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Agregar" prop="Agregar" [width]="35">
                                    <ng-template ngx-datatable-cell-template let-row="row" let-value="value"
                                        let-rowIndex="rowIndex">
                                        <div class="input-group-append btn-center mr-3">
                                            <a><button *ngIf="flagActivaCompra === true" class="btn btn-cart-plus"
                                                    placement="left" ngbTooltip="Ver detalle compra"
                                                    triggers="mouseenter:mouseleave"
                                                    (click)="openDetalleDeCompra(ordenDeCompra, rowIndex);">
                                                    <div class="text-center"><i class="fa fa-cart-plus fa-2x"></i></div>
                                                </button></a>
                                            <a><button id="disabled" *ngIf="flagActivaCompra === false"
                                                    class="btn btn-cart-plus" placement="left"
                                                    triggers="mouseenter:mouseleave" [disabled]="true">
                                                    <div class="text-center"><i class="fa fa-cart-plus fa-2x"></i></div>
                                                </button></a>
                                        </div>
                                    </ng-template>
                                </ngx-datatable-column>
                            </ngx-datatable>
                        </div>
                    </div>
                    <br>
                    <!-- Implementar Paginación: paginador y seleccionador!-->
                    <div style="justify-content: center !important;"
                        class="d-flex justify-content-between mt-2 mb-2 py-3">
                        <div style="display: flex;">
                            <pagination-controls class="custom-pagination" style="color: black !important;"
                                [id]="'paginationCatalogo'" [autoHide]="false" [responsive]="true"
                                previousLabel="Anterior" nextLabel="Siguiente"
                                (pageChange)="onPageChangeCatalogo($event)">
                            </pagination-controls>&nbsp;
                            <div style="margin-left: 15px !important;">
                                <select class="selector custom-select" style="width: auto; color: black !important;"
                                    name="pageSize" [(ngModel)]="configCatalogo.itemsPerPage">
                                    <option class="selector" [ngValue]="10">10</option>
                                    <option class="selector" [ngValue]="25">25</option>
                                    <option class="selector" [ngValue]="50">50</option>
                                    <option class="selector" [ngValue]="100">100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<footer>
    <div class="pie">
        <h2 class="text-footer mb-4 mt-3"><i>Customer Service</i></h2>
        <label class="mb-3">TELÉFONO: +56(2) 240 53560</label>
        <br>
        <label class="alinear">E-MAIL: service.las.west.customer@bayer.com</label>
        <br>
        <p><small><b>Valor mínimo de la compra: $500.000 + IVA</b></small></p>
        <p><small>Los precios expresiones NO incluyen impuestos, tasas, ni gastos de transporte.</small></p>
        <p><small>Precios y condiciones de venta están sujetos a variación sin previo aviso.</small></p>
        <p><small>Una vez entregada la semilla, no se aceptan devoluciones.</small></p>
        <p class="mb-6"><small>La orden de compra no garantiza la reserva y precio de la semilla.</small></p>
        <br>
            <div class="col-lg-12 mt-1">
                <div class="mb-4 content-center">
                        <nav>
                            <a target="_blank" href="https://www.instagram.com/seminis.sudamerica/"><i
                                    class="iconos fa fa-instagram fa-2x" aria-hidden="true"></i></a><small class="mb-2">
                                @Seminis.Sudamerica</small>
                        </nav>&nbsp;&nbsp;
                        <nav>
                            <a target="_blank" href="https://www.facebook.com/SeminisSudamerica/"><i
                                    class="iconos fa fa-facebook fa-2x"></i></a><small class="mb-2">
                                @Seminis.Sudamerica</small>
                        </nav>&nbsp;&nbsp;
                        <nav>
                            <a target="_blank" href="https://www.youtube.com/c/seminissudamerica"><i
                                    class="iconos fa fa-youtube-play fa-2x"></i></a><small class="mb-2"> Seminis
                                Sudamérica</small>
                        </nav>
                </div>
            </div>
        <br>
        <div class="mb-4 col-lg-12">
            <a target="_blank" class="iconos"
                href="https://www.vegetables.bayer.com/cl/es-cl.html"><small>https://www.vegetables.bayer.com/cl/es-cl.html</small></a>
        </div>
        <br>
    </div>
</footer>

<ng-template #ordenDeCompra>
    <app-detalle-orden (cancelaProductCompra)="recibeResultCarroVacio($event)" (eliminaProductoCarro)="obtieneProductosElimnadosCarro($event)" (envioCerrarModal)="cerrarModal();"
        (actualizoGrilla)="actualizarGrilla();" [nuevoProductoCarro]="detalleCompra" [mostarcarro]="mostarcarro">
    </app-detalle-orden>
</ng-template>

 
<ng-template #usuario>
    <app-usuarios (envioCerrarModal)="cerrarModal();">
    </app-usuarios>
</ng-template>

<ng-template #precios>
    <app-precios (envioCerrarModal)="cerrarModal();" (eliminaProductoCarro)="obtieneProductosElimnadosCarro($event)" >
    </app-precios>
</ng-template>-