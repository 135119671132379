import { Component, OnInit, Output, EventEmitter, OnDestroy, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import swal from 'sweetalert2';
import { DetallePedidoStoreService } from 'src/app/services/local-session/detalle-pedido-store.service';
import { LoginStoreService } from 'src/app/services/local-session/login-store.services';
import { BayerService } from 'src/app/services/bayer.service';
import { EmailService } from 'src/app/services/email.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { Email } from 'src/app/model/email';
import { EmailEnum } from 'src/app/enum/email-enum';
import { EmailReceptorEnum } from 'src/app/enum/email-receptor-enum';

@Component({
  selector: 'app-detalle-orden',
  templateUrl: './detalle-orden.component.html',
  styleUrls: ['./detalle-orden.component.scss']
})
export class DetalleOrdenComponent implements OnInit, OnDestroy {

  @Output() envioCerrarModal = new EventEmitter();
  @Output() actualizoGrilla = new EventEmitter();
  @Output() cancelaProductCompra = new EventEmitter();
  @Output() eliminaProductoCarro = new EventEmitter();
  @Input() nuevoProductoCarro: any;
  @Input() mostarcarro: any;  

  private carroCompraSubscription: Subscription;
  private emialSubscription: Subscription;

  public fechaHoy = new Date();
  public flagMostrarTabla: boolean = false;
  public flagCargando: boolean = false;
  public activaCierreModal: boolean = false;

  listaProductosCarro = [];
  listaEnvioProductos = [];
  envListCarroVacio = [];
  nuevoProductoAgregado: any;
  totalPedido: string;
  capturaIdCompra: number;
  fechaActual: string;
  comentario: string;
  email: string;
  mantenerCarro = false;
  activaDescuento: boolean;

  formatter = new Intl.NumberFormat('es-CL', {
    style: 'currency',
    currency: 'CLP',
    minimumFractionDigits: 0
  })

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private loginStoreService: LoginStoreService,
    private detallePedidoStoreService: DetallePedidoStoreService,
    private bayerService: BayerService,
    private emailService: EmailService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    setTimeout(() =>
      this.cargarComponente(), 200);
  }

  cargarComponente() {
    this.flagCargando = true;
    if (JSON.parse(sessionStorage.getItem('detallePedido')) != null) {
      this.listaProductosCarro = JSON.parse(sessionStorage.getItem('detallePedido'));
    }
    this.nuevoProductoAgregado = this.nuevoProductoCarro;
    this.listaProductosCarro = this.verificarProductoEnCarro(this.listaProductosCarro);
    this.activaDescuento  = this.listaProductosCarro[0].flagDescuento;
    this.comentario  = this.listaProductosCarro[0].comentario
    sessionStorage.setItem('detallePedido', JSON.stringify(this.listaProductosCarro));
    this.sumarTotalPedido(this.listaProductosCarro);
    this.flagCargando = false;
    this.flagMostrarTabla = true;
    this.activaCierreModal = false;
    let capturaEmail = this.loginStoreService.obtenerLogin();
    this.email = capturaEmail.email;
    this.fechaHoy = new Date();
    this.fechaActual = this.datePipe.transform(this.fechaHoy, "dd-MM-yyyy");

    //this.listaProductosCarro[0].descuento  = detalleUser.descuento ;
  }

  //Emite una output refrescar al mantenedor del componente padre catalogo
  actualizarGrilla() {
    this.actualizoGrilla.emit();
  }

  // Emite un output de cierre de modal al padre
  cerrarModal() {
    this.cancelaProdustos();
    sessionStorage.removeItem('detallePedido');
    this.activaCierreModal = true;
    this.envioCerrarModal.emit();
    this.mantenerCarro = false;
    console.log("detalle cerrarModal");
  }

  cancelaProdustos() {
    let flagCarroVacio = true;
    this.cancelaProductCompra.emit(flagCarroVacio);
  }

  seguirComprando() {
    sessionStorage.setItem('detallePedido', JSON.stringify(this.listaProductosCarro));
    this.envioCerrarModal.emit();
  }

  // call to update cell value
  updateValue(event, rowIndex) {
    this.envListCarroVacio.push(rowIndex);
    this.listaProductosCarro[rowIndex].cantidad = event.target.value;
  }

  disminuirCantidad(rowIndex) {
    if (this.listaProductosCarro[rowIndex].cantidad == 0) {
      this.listaProductosCarro[rowIndex].cantidad = 0;
      this.sumarTotalPedido(this.listaProductosCarro)
    }
    if (this.listaProductosCarro[rowIndex].cantidad > 0) {
      this.listaProductosCarro[rowIndex].cantidad = this.listaProductosCarro[rowIndex].cantidad - 1;
      this.listaProductosCarro[rowIndex].precioTotalPorItem = this.listaProductosCarro[rowIndex].cantidad * this.listaProductosCarro[rowIndex].precioporUnidad;
      this.sumarTotalPedido(this.listaProductosCarro)
    }
  }

  aumentarCantidad(rowIndex) {
    this.listaProductosCarro[rowIndex].cantidad = this.listaProductosCarro[rowIndex].cantidad + 1;
    this.listaProductosCarro[rowIndex].precioTotalPorItem = this.listaProductosCarro[rowIndex].cantidad * this.listaProductosCarro[rowIndex].precioporUnidad;
    this.sumarTotalPedido(this.listaProductosCarro);
  }

  eliminarProductodeCarro(rowIndex) {
    let obtieneProdEliminado = new Array();
    for (let x = 0; x < this.listaProductosCarro.length; x++) {
      if (x === rowIndex) {
        obtieneProdEliminado.push(this.listaProductosCarro[x]);
        this.listaProductosCarro.splice(x, 1);
        this.listaProductosCarro = [...this.listaProductosCarro];
      }
    }

    this.eliminaProductoCarro.emit(obtieneProdEliminado);
    this.sumarTotalPedido(this.listaProductosCarro);
    this.listaProductosCarro = this.listaProductosCarro;
    sessionStorage.setItem('detallePedido', JSON.stringify(this.listaProductosCarro));
  }

  sumarTotalPedido(listaProductos) {
    let suma = 0;
    let aux = 0;
    for (let i = 0; i < listaProductos.length; i++) {
      aux = listaProductos[i].cantidad * listaProductos[i].precioporUnidad;
      suma = suma + aux;
    }
    let totalCLP = suma;
    this.totalPedido = this.formatter.format(totalCLP);
  }

  verificarProductoEnCarro(listaCarro) {
    console.log("this.mostarcarro",this.mostarcarro);
    let contador = 0;
    if (listaCarro.length > 0) {
      for (let i = 0; i < listaCarro.length; i++) {
        if (listaCarro[i].id === this.nuevoProductoAgregado.id) {
          //listaCarro[i].cantidad = this.nuevoProductoAgregado.cantidad + listaCarro[i].cantidad;
          if (this.mostarcarro === 1 ){//  anieves
            listaCarro[i].cantidad =  listaCarro[i].cantidad;
            console.log("validar",this.mostarcarro);
            this.mostarcarro = 0;
          }else{
            listaCarro[i].cantidad = this.nuevoProductoAgregado.cantidad + listaCarro[i].cantidad;          
          }
          contador++;
        }
        listaCarro[i].precioTotalPorItem = listaCarro[i].cantidad * listaCarro[i].precioporUnidad;
      }

      if (contador === 0) {
        this.nuevoProductoAgregado.precioTotalPorItem = this.nuevoProductoAgregado.cantidad * this.nuevoProductoAgregado.precioporUnidad;
        listaCarro.push(this.nuevoProductoAgregado);
      }
      return listaCarro;

    }

    if (listaCarro.length == 0) {
      listaCarro.push(this.nuevoProductoAgregado);
      listaCarro[0].precioTotalPorItem = this.listaProductosCarro[0].cantidad * this.listaProductosCarro[0].precioporUnidad;
      return listaCarro;
    }
  }

  confirmarCarroCompra() {
    let envioProductoCarro = {
      materialId: null,
      variedadId: null,
      cantidad: null,
      precioUnitario: null,
      precioTotal: null,
    }
    this.listaProductosCarro[0].flagDescuento =   this.activaDescuento;
    this.listaProductosCarro[0].comentario =   this.comentario;

    for (let i = 0; i < this.listaProductosCarro.length; i++) {
      envioProductoCarro.materialId = this.listaProductosCarro[i].id;
      envioProductoCarro.variedadId = this.listaProductosCarro[i].variedadSemilla.id;
      envioProductoCarro.cantidad = this.listaProductosCarro[i].cantidad;
      envioProductoCarro.precioUnitario = this.listaProductosCarro[i].precioporUnidad;
      envioProductoCarro.precioTotal = this.listaProductosCarro[i].precioTotalPorItem;
      this.listaEnvioProductos.push(JSON.parse(JSON.stringify(envioProductoCarro)));
    }

    swal.fire({
      title: '¿Está seguro que desea confirmar el detalle de compra?',
      //html: '<span  class="paraLaInterrogracion"><b></b></span>',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#73bb6f',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      imageHeight: 71.5,
      imageWidth: 71.5,
    })
      .then((result) => {
        if (result.value) {
          if (this.listaProductosCarro !== null || this.listaProductosCarro !== undefined) {
            this.carroCompraSubscription = this.bayerService.carroCompraDetallePedido(this.listaEnvioProductos).subscribe(carro => {
              if (carro !== undefined && carro !== null) {
                let response = carro.mensaje;
                this.capturaIdCompra = carro.idCompra;
                this.enviarNotificacionEmail(this.capturaIdCompra);
                this.usuarioReceptorCompra(this.capturaIdCompra);
                setTimeout(() =>
                  swal.fire({
                    title: 'Atención',
                    text: 'Cargando datos ...',
                    imageUrl: 'assets/img/loadingCircucle.gif',
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false
                  }), 1);
                setTimeout(() =>
                  swal.fire('Solicitud exitosa', '<span><b><div class="alert alert-success" role="alert">Confirmación exitosa. ' + response + '!</div></b></span>', 'success'), 1000);
                this.listaProductosCarro = [];
                this.detallePedidoStoreService.borrarCarroCompra();
                this.actualizarGrilla();
                swal.close();
                this.cerrarModal();
              } else {
                this.toastrService.error('No se registraron los productos!', 'Error');
                swal.close();
              }
            });
          }
        } else {
          console.log('se cancela');
        }
      }).catch(e => {
        console.log(e);
      });
  }

  enviarNotificacionEmail(idCompra: number) {
    /*const email: Email = new Email();
    email.content = EmailEnum.textHeaderMessage + EmailEnum.textMessageFecha + this.fechaActual + EmailEnum.textMessageMonto + this.totalPedido;
    email.averageContent = EmailEnum.textAverageMessage;
    email.footerContent = EmailEnum.textFooterMessage;
    email.subject = EmailEnum.subject + idCompra;
    email.email = this.email;
*/


    let CondicionesAdicionales ='';

    console.log("this.listaProductosCarro[0].flagDescuento",this.listaProductosCarro[0].flagDescuento)
    if ( this.listaProductosCarro[0].flagDescuento != 'false' &&  this.listaProductosCarro[0].flagDescuento  != undefined )
    {
      CondicionesAdicionales ='Venta con Condiciones Adicionales';
      
      console.log("CondicionesAdicionales" , CondicionesAdicionales);
    }

    let cometa ='';
    if (this.listaProductosCarro[0].comentario  != undefined )
    {
      cometa ='<p style="color: #025802; text-align: justify;"> Comentario:'+ this.listaProductosCarro[0].comentario +'</p>';
    }
    let detallecompra = '<table style="border-color:#025802; border-collapse: collapse; width: 100%;" border="1"><tbody>'
                          +'<tr> '
                            +'<td style="background-color: #025802; color: white; width: 12%; text-align: center;">Especie</td>'
                            +'<td style="background-color: #025802; color: white; width: 12%; text-align: center;">Tipo</td>'
                            +'<td style="background-color: #025802; color: white; width: 12%; text-align: center;">Variedad</td>'
                            +'<td style="background-color: #025802; color: white; width: 12%; text-align: center;">Envase</td>'
                            +'<td style="background-color: #025802; color: white; width: 12%; text-align: center;">Unidad</td>'
                            +'<td style="background-color: #025802; color: white; width: 8%;  text-align: center;">Precio Unidad</td>'
                            +'<td style="background-color: #025802; color: white; width: 8%;  text-align: center;">Descuento </td>'
                            +'<td style="background-color: #025802; color: white; width: 8%;  text-align: center;">Precio</td>'
                            +'<td style="background-color: #025802; color: white; width: 8%;  text-align: center;">Cantidad</td>'
                            +'<td style="background-color: #025802; color: white; width: 8%;  text-align: center;">Valor total</td>'
                          +'</tr>';


    for (let x = 0; x < this.listaProductosCarro.length; x++) {


      console.log("ste es ",this.listaProductosCarro[x]);

     detallecompra = detallecompra +     
              '<tr> '
                  +'<td style="width: 12%; text-align: center;text-transform:uppercase">'+ this.listaProductosCarro[x].nombreEspecie +'</td>'
                  +'<td style="width: 12%; text-align: center;text-transform:uppercase">'+ this.listaProductosCarro[x].nombreTipo +'</td>'
                  +'<td style="width: 12%; text-align: center;text-transform:uppercase">'+ this.listaProductosCarro[x].nombreVariedad +'</td>'
                  +'<td style="width: 12%; text-align: center;text-transform:uppercase">'+ this.listaProductosCarro[x].envase.tipoEnvase +'</td>'
                  +'<td style="width: 12%; text-align: center;text-transform:uppercase">'+ this.listaProductosCarro[x].unidadCantidad +'</td>'
                  +'<td style="width: 8%;  text-align: center;">'+ this.formatter.format(this.listaProductosCarro[x].valorXunidad ) +'</td>'
                  +'<td style="width: 8%;  text-align: center;">'+ this.listaProductosCarro[x].descuento +' %</td>'
                  +'<td style="width: 8%;  text-align: center;">'+ this.formatter.format(this.listaProductosCarro[x].precioporUnidad) +'</td>'
                  +'<td style="width: 8%;  text-align: center;">'+ this.listaProductosCarro[x].cantidad +'</td>'
                  +'<td style="width: 8%;  text-align: center;">'+ this.formatter.format(this.listaProductosCarro[x].precioTotalPorItem) +'</td>'
              +'</tr>';
    }

    detallecompra = detallecompra           +  
        ' </tbody>'  
    +'</table>' ;

    const emaiReceptorCompra: Email = new Email();
    emaiReceptorCompra.content = EmailEnum.textHeaderMessage 
        + '<p style="color: #025802; text-align: center;" <strong> Orden de compra Nro: '+idCompra+  ' por '+ this.totalPedido + ' con fecha '+this.fechaActual+'</strong> </p>'
        + '<br>'
        + detallecompra
        + '<br>'
        + '<p style="color: #025802; text-align: justify;">&nbsp; &nbsp; &nbsp;<strong>'+CondicionesAdicionales+'</strong></p>'
        + cometa
        + '<br>'
        + '<p style="color: #025802; text-align: justify;">&nbsp; &nbsp; &nbsp;<strong>'+EmailEnum.textAverageMessage +' '+EmailEnum.textFooterMessage +'</strong></p>' ; 
    //+ EmailEnum.textMessageFecha 
    //+ this.fechaActual + EmailEnum.textMessageMonto + this.totalPedido;
    emaiReceptorCompra.averageContent = '';// '<p style="color: #025802; text-align: justify;">&nbsp; &nbsp; &nbsp;<strong>'+EmailEnum.textAverageMessage +'</strong></p>'  ; ;
    emaiReceptorCompra.footerContent = '';//  '<p style="color: #025802; text-align: justify;">&nbsp; &nbsp; &nbsp;<strong>'+EmailEnum.textFooterMessage +'</strong></p>'  ;
    emaiReceptorCompra.subject = EmailEnum.subject + idCompra;
    //emaiReceptorCompra.email = EmailReceptorEnum.emailReceptor;

    console.log("emaiReceptorCompraemaiReceptorCompra",emaiReceptorCompra),
    emaiReceptorCompra.email = this.email ;

    setTimeout(() =>
      this.toastrService.success('Detalle de compra ' + idCompra + '. Mail enviado exitosamente!', 'Success'), 200);
    
    this.emialSubscription = this.emailService.email(emaiReceptorCompra).subscribe(dataEmail => {
      if (dataEmail !== null) {
      }
    });

    emaiReceptorCompra.email = EmailReceptorEnum.emailReceptor ;
    this.emialSubscription = this.emailService.email(emaiReceptorCompra).subscribe(dataEmail => {
      if (dataEmail !== null) {
      }
    });

  }

  usuarioReceptorCompra(capturaIdCompra: number) {
    let CondicionesAdicionales ='';

    console.log("this.listaProductosCarro[0].flagDescuento",this.listaProductosCarro[0].flagDescuento)
    if ( this.listaProductosCarro[0].flagDescuento  != 'false' &&  this.listaProductosCarro[0].flagDescuento  != undefined )
    {
      CondicionesAdicionales ='Venta con Condiciones Adicionales'; 
      console.log("CondicionesAdicionales" , CondicionesAdicionales);
    }

    let cometa ='';
    if (this.listaProductosCarro[0].comentario  != undefined )
    {
      cometa ='<p style="color: #025802; text-align: justify;"> Comentario:'+ this.listaProductosCarro[0].comentario +'</p>';
    }
    let detallecompra = '<table style="border-color:#025802; border-collapse: collapse; width: 100%;" border="1"><tbody>'
                        +'<tr> '
                          +'<td style="background-color: #025802; color: white; width: 12%; text-align: center;">Especie</td>'
                          +'<td style="background-color: #025802; color: white; width: 12%; text-align: center;">Tipo</td>'
                          +'<td style="background-color: #025802; color: white; width: 12%; text-align: center;">Variedad</td>'
                          +'<td style="background-color: #025802; color: white; width: 12%; text-align: center;">Envase</td>'
                          +'<td style="background-color: #025802; color: white; width: 12%; text-align: center;">Unidad</td>'
                          +'<td style="background-color: #025802; color: white; width: 8%; text-align: center;">Precio Unidad</td>'
                          +'<td style="background-color: #025802; color: white; width: 8%; text-align: center;">Descuento</td>'
                          +'<td style="background-color: #025802; color: white; width: 8%; text-align: center;">Precio</td>'
                          +'<td style="background-color: #025802; color: white; width: 8%; text-align: center;">Cantidad</td>'
                          +'<td style="background-color: #025802; color: white; width: 8%; text-align: center;">Valor total</td>'
                        +'</tr>';


    for (let x = 0; x < this.listaProductosCarro.length; x++) {


      console.log("ste es ",this.listaProductosCarro[x]);

     detallecompra = detallecompra +     
              '<tr> '
                  +'<td style="width: 14%; text-align: center;text-transform:uppercase">'+ this.listaProductosCarro[x].nombreEspecie +'</td>'
                  +'<td style="width: 14%; text-align: center;text-transform:uppercase">'+ this.listaProductosCarro[x].nombreTipo +'</td>'
                  +'<td style="width: 14%; text-align: center;text-transform:uppercase">'+ this.listaProductosCarro[x].nombreVariedad +'</td>'
                  +'<td style="width: 14%; text-align: center;text-transform:uppercase">'+ this.listaProductosCarro[x].envase.tipoEnvase +'</td>'
                  +'<td style="width: 14%; text-align: center;text-transform:uppercase">'+ this.listaProductosCarro[x].unidadCantidad +'</td>'
                  +'<td style="width: 14%; text-align: center;">'+ this.listaProductosCarro[x].cantidad +'</td>'
                  +'<td style="width: 12%; text-align: center;">'+ this.formatter.format(this.listaProductosCarro[x].valorXunidad) +'</td>'                  
                  +'<td style="width: 10%; text-align: center;">'+ this.listaProductosCarro[x].descuento +' %</td>'
                  +'<td style="width: 14%; text-align: center;">'+ this.formatter.format(this.listaProductosCarro[x].precioporUnidad) +'</td>'
                  +'<td style="width: 14%; text-align: center;">'+ this.listaProductosCarro[x].cantidad +'</td>'
                  +'<td style="width: 16%; text-align: center;">'+ this.formatter.format(this.listaProductosCarro[x].precioTotalPorItem) +'</td>'
              +'</tr>';
    }

    detallecompra = detallecompra  
          +  ' </tbody>'  
        +'</table>' ;

    const emaiReceptorCompra: Email = new Email();
    emaiReceptorCompra.content = EmailEnum.textHeaderMessage 
        + '<p style="color: #025802; text-align: center;" <strong> Orden de compra Nro: '+capturaIdCompra+  ' por '+ this.totalPedido + ' con fecha '+this.fechaActual+'</strong> </p>'
        + '<br>'
        + detallecompra
        + '<br>'
        + '<p style="color: #025802; text-align: justify;">&nbsp; &nbsp; &nbsp;<strong>'+CondicionesAdicionales+'</strong></p>'
        + cometa
        + '<br>'
        + '<p style="color: #025802; text-align: justify;">&nbsp; &nbsp; &nbsp;<strong>'+EmailEnum.textAverageMessage +' '+EmailEnum.textFooterMessage +'</strong></p>' ; 
    //+ EmailEnum.textMessageFecha 
    //+ this.fechaActual + EmailEnum.textMessageMonto + this.totalPedido;
    emaiReceptorCompra.averageContent = '';// '<p style="color: #025802; text-align: justify;">&nbsp; &nbsp; &nbsp;<strong>'+EmailEnum.textAverageMessage +'</strong></p>'  ; ;
    emaiReceptorCompra.footerContent = '';//  '<p style="color: #025802; text-align: justify;">&nbsp; &nbsp; &nbsp;<strong>'+EmailEnum.textFooterMessage +'</strong></p>'  ;
    emaiReceptorCompra.subject = EmailEnum.subject + capturaIdCompra;
    emaiReceptorCompra.email = EmailReceptorEnum.emailReceptor;
    console.log( "linea 437");
    this.emailService.emailReceptorCompras(emaiReceptorCompra).subscribe(email => { });
  }

  volverCatalogoCompra() {

    this.activaCierreModal = true;
    this.envioCerrarModal.emit();
    this.mantenerCarro = false;
    console.log("detalle cerrarModal");


    let ruta = 'catalogo';
    setTimeout(() => {
      this.router.navigate([ruta]);
    }, 2000);
    this.listaProductosCarro[0].flagDescuento =   this.activaDescuento;
    this.listaProductosCarro[0].comentario =   this.comentario;
    this.seguirComprando();
    this.actualizarGrilla();
    console.log("volverCatalogoCompra",this.listaProductosCarro);
    this.cargarComponente();
  }

  ngOnDestroy(): void {
    if (this.carroCompraSubscription) {
      this.carroCompraSubscription.unsubscribe();
    }
    if (this.emialSubscription) {
      this.emialSubscription.unsubscribe();
    }
  }

}
