<section>
    <div class="modal-content modal-fondo">
        <div class="modal-header">
            <h4 class="modal-title titulo">Detalle de la orden</h4>
        </div>
    </div>
    <div class="modal-body">
        <div style="max-width: 99%;" class="container">
            <!-- La propiedad footerHeight oculta el pie de la tabla [footerHeight]="50" -->
            <div class="row pb-2">
                <div class="row-tabla">
                    <div class="row p-0">
                        <div class="col-md-12 p-0" *ngIf="flagCargando">
                            <ngb-progressbar [value]="100" [striped]="true" [animated]="true"></ngb-progressbar>
                        </div>
                        <ngx-datatable style="font-size: 14.5px !important;"
                            *ngIf="flagMostrarTabla" #detalleCompra class="material" [rows]="listaProductosCarro"
                            [columnMode]="'force'" [rowHeight]="'auto'" [scrollbarH]="false" [reorderable]="true"
                            [messages]="{emptyMessage:'No hay datos en la tabla.'}">

                            <ngx-datatable-column style="margin-left: 15px !important;" name="Especie" prop="nombreEspecie" [width]="50">
                                <ng-template ngx-datatable-cell-template let-value="value">
                                    {{value | uppercase}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Tipo" prop="nombreTipo" [width]="100">
                                <ng-template ngx-datatable-cell-template let-value="value">
                                    {{value | uppercase}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Variedad" prop="nombreVariedad" [width]="80">
                                <ng-template ngx-datatable-cell-template let-value="value">
                                    {{value | uppercase}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Envase" prop="tipoEnvase" [width]="60">
                                <ng-template ngx-datatable-cell-template let-value="value">
                                    {{value | uppercase}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Cantidad" prop="unidadCantidad" [width]="60">
                                <ng-template ngx-datatable-cell-template let-value="value">
                                    {{value}}
                                </ng-template>
                            </ngx-datatable-column> 

                            <ngx-datatable-column name="Precio Unidad" prop="valorXunidad"  [width]="80">
                                <ng-template ngx-datatable-cell-template let-value="value">
                                    {{value | currency:'CLP':''}}
                                </ng-template>
                            </ngx-datatable-column>


                            <ngx-datatable-column name="Descuento" prop="descuento" [width]="40">
                                <ng-template ngx-datatable-cell-template let-value="value">
                                    {{value}}  %
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column name="Precio descuento" prop="precioporUnidad" [width]="60">
                                <ng-template ngx-datatable-cell-template let-value="value">
                                    {{value | currency:'CLP':''}}
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column name="Total" prop="precioTotalPorItem" [width]="50">
                                <ng-template ngx-datatable-cell-template let-value="value">
                                    {{value | currency:'CLP':''}}
                                </ng-template>
                            </ngx-datatable-column>  

                            <ngx-datatable-column style="text-align: center !important;" name="Cantidad" prop="cantidad" [width]="150">
                                <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                                    <div class="row">
                                        <div class="col-sm-2">
                                            <button type="button" class="btn btn-danger btn-circle-red"
                                                (click)="disminuirCantidad(rowIndex)"><b>-</b></button>
                                        </div>
                                        <div class="col-sm-4 ml-4">
                                            <input disabled min="0" (blur)="updateValue($event, rowIndex)"
                                                (keyup.enter)="updateValue($event, rowIndex)"
                                                class="form-control text-center" [(ngModel)]="row.cantidad" />
                                        </div>
                                        <div class="col-sm-2">
                                            <button type="button" class="btn btn-success btn-circle"
                                                (click)="aumentarCantidad(rowIndex)"><b>+</b></button>
                                        </div>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Eliminar" prop="Eliminar" [width]="60">
                                <ng-template ngx-datatable-cell-template let-row="row" let-value="value"
                                    let-rowIndex="rowIndex">
                                    <a><button class="btn btn-trash" placement="left" ngbTooltip="Eliminar producto"
                                            (click)="eliminarProductodeCarro(rowIndex);"><i
                                                class="fa fa-trash fa-2x"></i></button></a>
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
        <div style="max-width: 99%;"  class="container">
            <h4>Completar pedido</h4>
            <div style="text-align: left;" class="card">
                <div style="margin-top: 5px;" class="row col-12 text-left">
                    <label>Condiciones Adicionales: 
                        <input style="margin-left: 5px;"  type="checkbox" id="descuento" 
                        [(ngModel)]="activaDescuento" >  
                    </label><br/>
                </div>
                <div class="row col-12">
                    <textarea style="margin: 5px; color: #9dacc6 ; border-color: #9dacc6;color: #9dacc6;" class="text-nowrap" [(ngModel)]="comentario" id="comentario" placeholder="Comentario" name="comentario" rows="1" cols="98%"></textarea>  
                </div>
                <div style="margin-top: 10px;" class="row">
                    <div class="col-sm-3 ml-0"></div>
                    <div class="col-3">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                            <label>Total del pedido: </label>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="col col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                            <input style="color: #857c85;" disabled value="{{totalPedido}}"
                                placeholder="--No hay registros--" type="text" class="form-control form-control-sm">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" style="color: white !important;" class="btn btn-raised btn-continuar mr-4"
            triggers="mouseenter:mouseleave" (click)="volverCatalogoCompra();"><b><i
                    class="fa fa-shopping-cart ml-0 mr-2"></i>Continuar Comprando</b></button>
        <button type="button" style="color: white !important;" class="btn btn-raised btn-success btn-excel"
            triggers="mouseenter:mouseleave" (click)="confirmarCarroCompra();"><b><i
                    class="fa fa-check ml-0 mr-2"></i>Confirmar Orden</b></button>
        <!--<button type="button" style="color: white !important;" class="btn btn-raised btn-danger btn-cerrar"
            (click)="cerrarModal();"><b>Cerrar</b></button>-->
    </div>
</section>