<form [formGroup]="ngForm">

    <div class="inputGroup inputGroup1" style="text-align: center">
        <img src="../../../assets/icons/ico/bayer-chiquito.png" width="250" height="100">
    </div>

    <div style="text-align: center">
        <label>Bienvenido a Seminis Bayer</label>
    </div>
    <div class="form-group">
        <div class="inputGroup inputGroup1">
            <label for="user1">Usuario</label>
            <input formControlName="user" type="text" placeholder="Ingrese usuario" id="user" name="user"
                 required />
            <span class="indicator"></span>
        </div>
        <div class="row">
            <div class="col-md-12 mb-3">
                <div class="inputGroup inputGroup2">
                    <label for="password">Contraseña</label>
                    <input placeholder="Ingrese contraseña" [type]="hide ? 'text' : 'password'" name="password"
                        formControlName="password" class="form-control password1" value="clave" />
                    <span (click)="mostrarPassword();" [class]="showEye ? 'fa fa-eye fa-2x' : 'fa fa-eye-slash fa-2x' "
                        class="mb-5 mr-4 password-icon show-password"></span>
                </div>
            </div>
        </div>

        <div *ngIf="(ngForm.controls['user'].invalid && (ngForm.controls['user'].dirty || ngForm.controls['user'].touched)) || (ngForm.controls['password'].invalid && (ngForm.controls['password'].dirty || ngForm.controls['password'].touched))"
            class="alert alert-danger">
            <div *ngIf="ngForm.get('user').hasError('required')">
                 Debe ingresar cuenta de usuario.
            </div>
            <div *ngIf="ngForm.get('password').hasError('required')">
                 Debe ingresar contraseña de usuario.
            </div>
            <div *ngIf="ngForm.get('user').hasError('minlength')">
                 La cuenta de usuario debe tener minimo 5 caracteres.
            </div>
            <div *ngIf="ngForm.get('password').hasError('minlength')">
                 La contraseña debe tener minimo 5 caracteres.
            </div>
        </div>

        <div class="inputGroup inputGroup3">
            <button style="margin-bottom: 15px !important;" id="login" type="button" (click)="iniciarSesion()" [disabled]="ngForm.valid == false"
                >Iniciar Sesión
                <i class="fa fa-sign-in" aria-hidden="true"></i>
            </button>
            <nav>
                <a routerLink="/recuperar-contrasena" class="txtRestablecerPass"><b>Recuperar contraseña</b></a>
            </nav>
        </div>
    </div>
</form>

<div *ngIf="obtLogin.mensaje" class="alert alert-success toast-container toast-wrapper" role="alert">
    <h4 class="alert-heading toast-header"><b>Bienvenido!</b></h4>
    <hr>
    <p class="mb-0"><b>{{obtLogin.mensaje}}</b></p>
</div>