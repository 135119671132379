// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //API: 'https://192.168.11.233:8443/',  
  //API: 'https://3.137.148.251:8443/',
  API:'https://backend.portalbayerlatam.com:8443/',
  //REINTENTOS?: 4,
  token: '',
  firebaseConfig: {
    apiKey: "AIzaSyA2o9qrvruAy7mq0uWE1G8YGvZM-yb3xN8",
    authDomain: "bayer-74fb6.firebaseapp.com",
    projectId: "bayer-74fb6",
    storageBucket: "bayer-74fb6.appspot.com",
    messagingSenderId: "749690517135",
    appId: "1:749690517135:web:7faff7a8ede61a165e22fa"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
