import { Component, OnInit, Output, EventEmitter, OnDestroy, Input, ViewChild, enableProdMode } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import swal from 'sweetalert2';
import { DetallePedidoStoreService } from 'src/app/services/local-session/detalle-pedido-store.service';
import { LoginStoreService } from 'src/app/services/local-session/login-store.services';
import { BayerService } from 'src/app/services/bayer.service';
import { EmailService } from 'src/app/services/email.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { Email } from 'src/app/model/email';
import { EmailEnum } from 'src/app/enum/email-enum';
import { EmailReceptorEnum } from 'src/app/enum/email-receptor-enum';
import { LoginService } from 'src/app/services/login.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DatatableComponent } from '@swimlane/ngx-datatable';
@Component({
  selector: 'app-precios',
  templateUrl: './precios.component.html',
  styleUrls: ['./precios.component.scss']
})
export class preciosComponent  implements OnInit, OnDestroy {
  @Output() envioCerrarModal = new EventEmitter();
  @Output() actualizoGrilla = new EventEmitter();
  @Output() cancelaProductCompra = new EventEmitter();
  @Output() eliminarprecio = new EventEmitter();
  @Input() nuevoProductoCarro: any;
  @Input() mostarcarro: any;  

  @ViewChild(DatatableComponent) public tabCatalagoProductos: DatatableComponent;
  private listaTodoProductsSubscription: Subscription;
  private modalReference: NgbModalRef;
  private carroCompraSubscription: Subscription;
  private emialSubscription: Subscription;

  public arrayProductos = new Array();

  public configCatalogo = {
    id: 'paginationCatalogo',
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: this.arrayProductos.length
  };

  public fechaHoy = new Date();
  public flagMostrarTabla: boolean = true;
  public flagCargando: boolean = false;
  public activaCierreModal: boolean = false;  
  tipo: any;
  variedad: any;
  especie: any;
  fileName = '';
  listaProductosCarro = [];
  listaEnvioProductos = [];
  envListCarroVacio = [];
  nuevoProductoAgregado: any;
  totalPedido: string;
  capturaIdCompra: number;
  fechaActual: string;
  comentario: string;
  email: string;
  mantenerCarro = false;
  activaDescuento: boolean;
  formatter = new Intl.NumberFormat('es-CL', {
    style: 'currency',
    currency: 'CLP',
    minimumFractionDigits: 0
  })
  datosusuarios: any;
  selectedFiles: FileList;
  currentFileUpload: File;


  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private loginStoreService: LoginStoreService,
    private loginService: LoginService,
    private detallePedidoStoreService: DetallePedidoStoreService,
    private bayerService: BayerService,
    private emailService: EmailService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    console.log("inicio usuario") 
    /*setTimeout(() =>
      this.cargarProductosActualizados(), 200
      );
*/
     // this.cargarProductosActualizados();
  }

  Mostarproductos(){
    this.cargarProductosActualizados();
  }

  cargarComponente() {

    this.flagCargando = true;
    this.loginService.detalleTodosUsuarios().subscribe(users =>{          
      this.listaProductosCarro =  users; 
      for (let x = 0; x < users.length; x++) {
        if (users[x].enabled == true){
          users[x].estado = "Habilitado";
        } else {
          users[x].estado = "Deshabilitado";
        }
        console.log("Habilitado",users[x].estado);
      }        
      console.log("detalleTodosUsuarios",users, "ffff", this.listaProductosCarro);
    });
  }

  // Emite un output de cierre de modal al padre
  cerrarModal() {
    console.log("cerrarModal");
    this.activaCierreModal = true;
    this.envioCerrarModal.emit();
    this.mantenerCarro = false;    
    console.log("cerrarModalfin");
  }

  async upload() {
          this.currentFileUpload = this.selectedFiles.item(0);
        console.log("upload",this.currentFileUpload);
        var hola = await this.loginService.uploadfile(this.currentFileUpload);
        console.log("Fin upload", hola);

    this.cargarProductosActualizados();
  }

  selectFile(event) {
    this.selectedFiles = event.target.files;
  }

  onFileSelected(event) {
    const dddd = event.target.files;
    this.currentFileUpload = this.selectedFiles.item(0); 
    console.log(this.currentFileUpload);
  }

  ngOnDestroy(): void {

  }

  onPageChangeCatalogo(event) {
    if (event) {
      this.configCatalogo.currentPage = event;
    }
  }

  deletePrice(event){
    if (event != null || event != undefined) {
      console.log(event)
      for (let i = 0; i < this.arrayProductos.length; i++) {
        if (i === event){
          console.log("eliminarPrecio",this.arrayProductos[i]);
          this.bayerService.delete(this.arrayProductos[i].id).subscribe();
          
          setTimeout(() =>
            this.cargarProductosActualizados(), 200
          );
        }
      }
    } else {
      console.error('No recibe evento!',event);
    }
  }

  cargarProductosActualizados() {

    setTimeout(
      () =>
        swal.fire({
          title: 'Atención!',
          text: 'Cargando datos ...',
          imageUrl: 'assets/img/loadingCircucle.gif',
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
        }),
      100
    );
    this.listaTodoProductsSubscription = this.bayerService.listarTodoProducto().subscribe((productAct) => {

      console.log("productAct",productAct);
      if (productAct !== null || productAct !== undefined) {
        for (let i = 0; i < productAct.length; i++) {
          productAct[i].numero = i +1;
          productAct[i].tipoEnvase = productAct[i].envase.tipoEnvase;
          productAct[i].nombreEspecie = productAct[i].especieSemilla.nombreEspecie;
          productAct[i].nombreTipo = productAct[i].tipoSemilla.nombreTipo;
          productAct[i].nombreVariedad = productAct[i].variedadSemilla.nombreVariedad.toLowerCase();;
          productAct[i].unidadMedida = productAct[i].unidad.unidadMedida;
          productAct[i].cantidadMedida = productAct[i].unidad.cantidad;
          productAct[i].unidadCantidad = `${productAct[i].unidad.cantidad}` +' '+`${productAct[i].unidad.unidadMedida}`;
          productAct[i].cantidad = 0;
          productAct[i].valorTotal = productAct[i].preciosPorMaterial.valorUnidad * productAct[i].Cantidad; 
          productAct[i].precioporUnidad = productAct[i].preciosPorMaterial.valorUnidad;
        }
        console.log("productAct",productAct);
        this.arrayProductos = productAct;

        this.configCatalogo.totalItems = this.arrayProductos.length;
        this.tabCatalagoProductos.offset = Math.floor((this.configCatalogo.totalItems) / this.configCatalogo.itemsPerPage);


        swal.close();
      } else {
        console.error('No hay productos!');
      }
    });
  }

  buscarProductos() {
    if(this.tipo == null){
      this.tipo = 0;
    }

    if(this.variedad == null){
      this.variedad = 0;
    }

    this.ejecutarListaProducto(this.especie, this.tipo, this.variedad);
    this.especie = null; 
    this.tipo = null;
    this.variedad = null;
  }

  ejecutarListaProducto(idEspacie, idTipo, idVariedad) {

    let detalleUser = JSON.parse(sessionStorage.getItem('detalleUser'))
    if (idEspacie !== 0) {
      this.flagCargando = true;

      this.flagCargando = false;
      this.listaTodoProductsSubscription = this.bayerService.filtraListaProducto(idEspacie, idTipo, idVariedad).subscribe(productList => {
        if (productList !== null || productList !== undefined && productList.length > -1) {
          for (let i = 0; i < productList.length; i++) {
            productList[i].tipoEnvase = productList[i].envase.tipoEnvase;
            productList[i].nombreEspecie = productList[i].especieSemilla.nombreEspecie;
            productList[i].nombreTipo = productList[i].tipoSemilla.nombreTipo;
            productList[i].nombreVariedad = productList[i].variedadSemilla.nombreVariedad.toLowerCase();;
            productList[i].cantidadMedida = productList[i].unidad.cantidad;
            productList[i].unidadMedida = productList[i].unidad.unidadMedida;
            productList[i].precioporUnidad = productList[i].preciosPorMaterial.valorUnidad  - productList[i].preciosPorMaterial.valorUnidad *detalleUser.descuento /100;
            productList[i].unidadCantidad = `${productList[i].unidad.cantidad}` +' '+ `${productList[i].unidad.unidadMedida}`;
            productList[i].cantidad = 0;
            productList[i].valorXunidad = productList[i].preciosPorMaterial.valorUnidad;
            productList[i].valorTotal = productList[i].preciosPorMaterial.valorUnidad * productList[i].Cantidad;            
            productList[i].descuento = detalleUser.descuento;
          }
          console.log("productList",productList);
          swal.close();
          this.arrayProductos = productList;

          this.configCatalogo.totalItems = this.arrayProductos.length;
          this.tabCatalagoProductos.offset = Math.floor((this.configCatalogo.totalItems) / this.configCatalogo.itemsPerPage);

        } else if (productList.length === undefined) {
          let msg = productList.error[0].mensaje
          setTimeout(() =>
            swal.fire(
              'Error',
              '<span><b><div class="alert alert-danger" role="alert">' + msg +
              '</div></b></span>',
              'error'
            ),
            1000
          );
        }
      });
    } else {
      this.flagCargando = false;
      setTimeout(
        () =>
          swal.fire(
            'Error',
            '<span><b><div class="alert alert-danger" role="alert">' +
            'No debe ejecutar una especie no válida!' +
            '</div></b></span>',
            'error'
          ),
        1000
      );
    }
    //this.especie = null;
    //this.flagActivoTipo = false;
  }
}
